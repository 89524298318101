import React, { useCallback, useEffect, useContext, useState, useRef  } from 'react'
import {Row, Col,Table,Modal} from 'react-bootstrap'
import api from '../../config/axios';
import Menu from './Menu';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { Button, Stack } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import { signal } from '@preact/signals'
import { useSignals } from "@preact/signals-react/runtime";
import { InputLabel, MenuItem, Select } from '@mui/material';
import { AuthenticateContext } from '../../config/AuthContext';
import {jours, creneaux} from './../../config/JourCreneauSalles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa9, faDeleteLeft, faEdit, faFileExcel, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import Checkbox from '@mui/material/Checkbox';
import logo from '../../images/logo.png';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
//---------------signals-------
const yearSignal = signal(0);
const listPlannings = signal([]);
const uniquesNames = signal([]);
const creneau = signal("");
const jour = signal("");
const profId = signal(0);
const filierSignal = signal("");
const moduleID = signal(0);
const filieresOfProf = signal([]);
const modulesOfProf = signal([]);
const session = signal(0);
const profplaning = signal({});
const sallesDispo = signal([]);
const salleSignal = signal("");
const dateAbsenceSignal = signal("");
const allRattrapages = signal([]);
const uniqDepartement = signal([]);
const uniqFiliere = signal([]);
const dateRattrapage = signal("");
const ratrappageListPrint = signal([]);
const dateMin = signal(null);
const dateMax = signal(null);
const nomProfRatt = signal("");
const prenomProfRatt = signal("");
//-------------------------------
function AddRattrapage() {
    const [authInfo,setAuthInfo] = useContext(AuthenticateContext);
    const [selectedRows, setSelectedRows] = useState([]);
    // Fonction pour cocher/décocher une ligne
  const toggleCheckbox = (rat) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(rat.id)) {
        return prevSelectedRows.filter((id) => id !== rat.id);
      } else {
        return [...prevSelectedRows, rat.id];
      }
    });
  };

  // Fonction pour décocher toutes les cases
  const deselectAll = () => {
    setSelectedRows([]);
    ratrappageListPrint.value=[];
  };
    //--------------------modal2 added---------------
    const [show, setShow] = useState(false);
    const [showDel, setShowDel] = useState(false);
    const [showprint, setShowprint] = useState(false);
    const [showprintProf, setShowprintProf] = useState(false);
    const [showprintControle, setShowprintControle] = useState(false);
    const modalContentRef = useRef();
    const handleClose = () => setShow(false);
    const handleCloseprint = () => setShowprint(false);
    const handleCloseprintProf = () => setShowprintProf(false);
    const handleCloseprintControle = () => setShowprintControle(false);
    const [msg, setMsg] = useState("");
    const [classe, setClasse] = useState("text-info h4");
    const handleCloseDelete = () => setShowDel(false);
    const [del, setDel] = useState(0);
    const handleShowDel = (delId) => {setDel(delId); setShowDel(true); }
//--------------------------------------------------------------
    //===========================getAllPlanningsByYear=====================================
const getAllPlanningsByYear = useCallback(async (annee) => {
    console.log("im getAllPlanningsByYear im executing.....");
    let A1 = annee;
    let A2 = A1 + 1;
    const url = "api/allplannins/"+A1+"/"+A2;
      api.get(url)
      .then(rep =>{
          let lst = rep.data.plannings;
          listPlannings.value = lst;
          console.log(listPlannings.value);
          //------------------------------------
          uniquesNames.value = listPlannings.value.map(item => ({ id_professor:item.id_professor,nom_fr: item.nom_fr, prenom_fr: item.prenom_fr })) // Extraire nom_fr et prenom_fr
          .filter((value, index, self) => 
            index === self.findIndex((t) => (
              t.nom_fr === value.nom_fr && t.prenom_fr === value.prenom_fr
            )) // Garder seulement les objets uniques
          )
          .sort((a, b) => a.nom_fr.localeCompare(b.nom_fr)); // Trier par nom_fr

          console.log(uniquesNames.value);
        
      })
      .catch(err => {
         console.error(err);
      });    
  },[]);
  //===========================getAllRattrapagesByYear=====================================
const getAllRattrapagesByYear = useCallback(async (annee) => {
    console.log("im getAllPlanningsByYear im executing.....");
    let A1 = annee;
    let A2 = A1 + 1;
    const url = "api/allrattrapages/"+A1+"/"+A2;
      api.get(url)
      .then(rep =>{
          let lst = rep.data.rattrapages;
          allRattrapages.value = lst;
          //------inverser la liste ------
           let listrevers = [];
           listrevers = allRattrapages.value.reverse();
           allRattrapages.value = listrevers;
           //--------------------------------
          //-----------liste d'uniques filières & départements
          let lstdep = lst.map(d => d.libelle_departement);
          let lstfil = lst.map(d => d.libelle_filiere);
          const uniqueDep = lstdep.filter((item, index, arr) => arr.indexOf(item) === index);
          const uniqueFil = lstfil.filter((item, index, arr) => arr.indexOf(item) === index);
          uniqDepartement.value = uniqueDep;
          uniqFiliere.value = uniqueFil;
          //------------------------------------------------
         // setIndicateur(true);
          console.log("all rattrapages: ",allRattrapages.value);
      })
      .catch(err => {
         console.error(err);
      });    
  },[]);
     //---------------------get Anne univ ----------------------
const getYearUniv =useCallback(async () =>{
    console.log("im getYearUniv im executing.....");
    const url = "/api/anneeuniv";
    api.get(url)
    .then(rep =>{
        yearSignal.value = rep.data.annee[0].annee_univ;
        getAllPlanningsByYear(yearSignal.value);
        getAllRattrapagesByYear(yearSignal.value);
                      
    }).catch(err => {
      console.error(err);
    });
  },[]);
  //---------
  useEffect(() =>{
    getYearUniv();
  },[]);
  //--------------------------------salles disponibles-----
  const getSallesDisp =async () =>{
    let year1 = yearSignal.value;
    let year2 = year1+1;
    api.get('/api/sallesdispo/'+year1+'/'+year2+'/'+jour.value+'/'+creneau.value+'/'+session.value)
    .then(rep =>{
        
        console.log(rep.data.salles_disponibles);
        sallesDispo.value = rep.data.salles_disponibles;  
      }).catch(err => {
    console.error(err);
    });
}
//------------------------Ajouter le rattrapage-----------
const addRattrapage = async () => { 
    api.post('/api/rattrapage',{
      "date_rattrapage":dateRattrapage.value,
      "id_professor":profplaning.value.id_professor,
      "id_module":profplaning.value.id_module,
      "session":session.value,
      "groupe":profplaning.value.groupe,
      "jour":jour.value,
      "creneau":creneau.value,
      "salle":salleSignal.value,
      "annee_univ":yearSignal+"/"+(yearSignal+1),
      "date_absence":dateAbsenceSignal.value
    },
    {
      headers: {
        'auth-token': authInfo.token
        }
    }).then(rep => {
     // getYearUniv();// dans cette fonction il y a aussi la fonction de récupération des plannings
      console.log("******************************************************");
      console.log(rep.data);
      if(rep.data.success){
        setMsg("<b>Rattrapage bien programmé</b>");
        setClasse("text-success");
        
      }else{
        
            if(rep.data.planning1.length > 0){
              setMsg("Impossible d'ajouter ce rattrapage!<br/> Créneau attribué à "+rep.data.planning1[0].prenom_fr+" "+rep.data.planning1[0].nom_fr);
            }
            if(rep.data.planning2.length > 0){
              setMsg("Impossible d'ajouter ce rattrapage!<br/>  Cet enseignat(e) n'est pas disponible dans cet créneau, il (elle) assure le cours "+rep.data.planning2[0].module_name+" dans la salle: "+rep.data.planning2[0].salle);
            }
            if(rep.data.is_chevauchement){
              let actualSem = rep.data.actualSem;
              let chev = rep.data.overlapedWith[0];
              
              setMsg("Impossible d'ajouter ce rattrapage!<br/>  Chevauchement entre les semestres: <br> module de sem:"+actualSem+"<br> chevauché avec sem:"+chev);
            }
          
            setClasse("text-danger");
      }
      getAllRattrapagesByYear(yearSignal.value);
      setShow(true);
    
    });
  }

  //==========================Delete rattrapage========================
const deleteRattrapage = async (id) => {
  api.delete('/api/deleterattrapage/' +id,{
    headers: {
      'auth-token': authInfo.token
      }
  }).then(rep => {
    console.log(rep.data);
    getAllRattrapagesByYear(yearSignal.value);
    
  }).catch(err => {
    console.log(err);
  });
}
//----------------------ListRattrapagesPrint--------------
const addOrDeleteRattrapage = (event, rat)=>{
  toggleCheckbox(rat);
  if (event.target.checked){
    ratrappageListPrint.value = [...ratrappageListPrint.value,rat];
  }else{
    ratrappageListPrint.value = ratrappageListPrint.value.filter((item) => item !== rat);
  }
  //--------------tri-------------------
  const sortedRattrapageList = [...ratrappageListPrint.value].sort((a, b) =>
    a.nom_fr.localeCompare(b.nom_fr)
  );
  ratrappageListPrint.value = sortedRattrapageList;
  console.log(ratrappageListPrint.value);
  ///---------------Prof    ratt ---------------
  if (ratrappageListPrint.value.length>0){
    nomProfRatt.value = ratrappageListPrint.value[0].nom_fr;
    prenomProfRatt.value = ratrappageListPrint.value[0].prenom_fr;
  }
  
  //-----------------dateMin & dateMax --------------------
   if(ratrappageListPrint.value.length > 0) {
    let dates = ratrappageListPrint.value.map((item) =>item.date_rattrapage);
    console.log(dates);
    // Convertir les dates en objets Date
const dateObjects = dates.map(date => new Date(date));
// Trouver la date minimum et la date maximum
const minDate = new Date(Math.min(...dateObjects));
const maxDate = new Date(Math.max(...dateObjects));
dateMin.value = minDate.toISOString();
dateMax.value = maxDate.toISOString();
console.log('Date minimale:', dateMin.value);
console.log('Date maximale:', dateMax.value);
   }
  //----------------------------------------------------
}
 // Fonction pour imprimer le contenu de la modal
 const contentRef = useRef(null);//étudiant
 const contentRef2 = useRef(null); //prof
 const contentRef3 = useRef(null); //controle absence de rattrapage
 //-------------------pdf étudiants----------------
    const exportPDFetudiant = () => {
        const input = contentRef.current;
        html2canvas(input, { 
            scale: 2,
            useCORS: true 
        }).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('l', 'mm', 'a4');
            const imgWidth = 285; 
            const pageHeight = 210 ;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            while (heightLeft > pageHeight) {
                heightLeft -= pageHeight;
                position -= pageHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            }
            pdf.save('rattrapageEtudiants.pdf');
            ratrappageListPrint.value =[] // vider la liste
        });
    };
  //--------------------------pdf prof--------------------
  const exportPDFprof = () => {
    const input = contentRef2.current;
    html2canvas(input, { 
        scale: 2,
        useCORS: true 
    }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('l', 'mm', 'a4');
        const imgWidth = 285; 
        const pageHeight = 210 ;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        while (heightLeft > pageHeight) {
            heightLeft -= pageHeight;
            position -= pageHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        }
        pdf.save('rattrapageProf.pdf');
        ratrappageListPrint.value =[] // vider la liste
    });
};
//--------------------------pdf Controle de rattrapage--------------------
const exportPDFcontrole = () => {
  const input = contentRef3.current;
  html2canvas(input, { 
      scale: 2,
      useCORS: true 
  }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 195; 
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      while (heightLeft > pageHeight) {
          heightLeft -= pageHeight;
          position -= pageHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      }
      pdf.save('controleRattrapage.pdf');
      ratrappageListPrint.value =[] // vider la liste
  });
};
//--------------------------------------------------------


    useSignals();  
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Menu/>
    
        <Grid container spacing={2} marginTop={4}>
                <Grid item xs={12}>
                <div className='display-3 text-primary text-center'>Gestion des rattrapages: {yearSignal.value}/{yearSignal.value+1}</div>
                </Grid>

      {/**===============================Session================================================ */}
<Grid item xs={6}>
        <FormControl className='col-12' sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label">Session</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={session.value}
          label="Session"
          onChange={e=> session.value = e.target.value}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {[1,2].map((s,i) =><MenuItem value={s} key={i}>{"Session "+ s}</MenuItem>)}
        </Select>
       
      </FormControl> 
      </Grid>

      
      {/**===============================Prof================================================ */}
        <Grid item xs={6}>
        <FormControl className='col-12' sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label">Prof</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={profId.value}
          label="Prof"
          onChange={e=>{profId.value = e.target.value; filieresOfProf.value = [...new Set(
            listPlannings.value
              .filter(item => item.id_professor === e.target.value) // Filtrer par id_professor
              .map(item => item.libelle_filiere) // Extraire les libelle_filiere
          )];}}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {uniquesNames.value.map((prof,i) =><MenuItem value={prof.id_professor} key={i}>{prof.nom_fr} {prof.prenom_fr}</MenuItem>)}
        </Select>
       
      </FormControl>
        
      </Grid>
      {/**===============================Filiere================================================ */}
      <Grid item xs={6}>
        <FormControl className='col-12' sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label2">Filière</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label2"
          value={filierSignal.value}
          label="Créneau"
          onChange={e=>{filierSignal.value = e.target.value; modulesOfProf.value = listPlannings.value.filter(item=>item.libelle_filiere === filierSignal.value).filter(item=>item.id_professor === profId.value).filter(item=>item.session === session.value);}}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {filieresOfProf.value.map((filier,i) =><MenuItem value={filier} key={i}>{filier}</MenuItem>)}
        </Select>
       
      </FormControl>
      </Grid>
      {/**=============================================================================== */}      

{/**===============================Module================================================ */}
<Grid item xs={6}>
        <FormControl className='col-12' sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label">Module</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={moduleID.value}
          label="Prof"
          onChange={e=>{moduleID.value=e.target.value; profplaning.value = listPlannings.value.find(item => item.id_professor === profId.value && item.id_module === moduleID.value);}}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {modulesOfProf.value.map((prof,i) =><MenuItem value={prof.id_module} key={i}>{prof.module_name +" - "+prof.groupe}</MenuItem>)}
        </Select>
       
      </FormControl> 
      </Grid>
       
       {/**---------------------------------information planning ------------- */}
       { (profplaning.value.id_professor != null)&&
        <Grid item xs={12}>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
       <ListItem>
         <ListItemAvatar>
           <Avatar>
             <AccessAlarmsIcon />
           </Avatar>
         </ListItemAvatar>
         <ListItemText primary="Créneau" secondary={profplaning.value.jour+":"+profplaning.value.creneau} />
       </ListItem>     
     </List>
         </Grid>
       }
        
       {/**-------------------------------------------------------------------*/}

       <Grid item xs={12}>
       <div className='h2 text-primary text-center'>Programmer un rattrapage</div>
      </Grid>
      {/**--------------------------------Date d'absence--------------------------------- */}
      
      <Grid item xs={6}>
      <div className='form-group'>
                <label className='update_label'>تاريخ الغياب</label>
               <input type='date' value={dateAbsenceSignal.value} onChange={(e)=>{dateAbsenceSignal.value = e.target.value;}} className='form-control modif'/>
          </div>
      </Grid>
      {/**--------------------------------Date de rattrapage--------------------------------- */}
      
      <Grid item xs={6}>
      <div className='form-group'>
                <label className='update_label'>Date de rattrapage</label>
               <input type='date' value={dateRattrapage.value} onChange={(e)=>{dateRattrapage.value = e.target.value; let dateChoisie = new Date(e.target.value); jour.value=jours[dateChoisie.getDay()];}} className='form-control modif'/>
          </div>
      </Grid>
    
 {/**===============================jour================================================ */}
        <Grid item xs={6}>
        <FormControl className='col-12' sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label">jour</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={jour.value}
          label="jour"
          disabled
          onChange={e=>jour.value = e.target.value}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {jours.map((j,i) =><MenuItem value={j} key={i}>{j}</MenuItem>)}
        </Select>
       
      </FormControl>
        
      </Grid>
      {/**===============================Créneau================================================ */}
      <Grid item xs={6}>
        <FormControl className='col-12' sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label2">Créneau</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label2"
          value={creneau.value}
          label="Créneau"
          onChange={e=>{creneau.value = e.target.value; getSallesDisp();}}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {creneaux.map((j,i) =><MenuItem value={j} key={i}>{j}</MenuItem>)}
        </Select>
       
      </FormControl>
      </Grid>
      {/**===============================Salles================================================ */}
        <Grid item xs={6}>
        <FormControl className='col-12' sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label">Salle</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={salleSignal.value}
          label="Salle"
          onChange={e=>{salleSignal.value=e.target.value; }}
        >
          <MenuItem value="">
            <em>Salles disponibles</em>
          </MenuItem>
          {sallesDispo.value.map((salle,i) =><MenuItem value={salle.name_fr} key={i}>{salle.name_fr}</MenuItem>)}
        </Select>
       
      </FormControl> 
      </Grid>
     
     
      {/**=============================================================================== */}      
      <Grid container spacing={2} marginBottom={4}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6} marginTop={2}> 
                <Button onClick={addRattrapage} className='col-12'  variant="contained" color="success">
                        Ajouter le rattrapage
                </Button>
            </Grid>
            <Grid item xs={3}></Grid>
    </Grid>
                
        </Grid>
    {/**------------------------------------- */}
    {/**************************affichage de rattrapage**************************** */}
    <Row>
     <Col>
      <hr className='trait'/>
      <h3>Rattrapages</h3>
      </Col>
      <Col md={12}>
      
      <Button variant="contained" color="success" className='col-3 mb-2' onClick={()=>null} ><FontAwesomeIcon size='2x' icon={faFileExcel} /></Button>
      </Col>
      <Col md={12}>
      <Button variant="contained" color="secondary" className='col-2 mb-2' style={{"marginLeft":10}} onClick={deselectAll}>
          Décocher tout
        </Button>
      <Button variant="contained" color="primary" className='col-3 mb-2' style={{"marginLeft":20}} onClick={()=>setShowprint(true)} ><FontAwesomeIcon size='2x' icon={faFilePdf} />Etat-étud</Button>
      <Button variant="contained" color="primary" className='col-3 mb-2' style={{"marginLeft":20}} onClick={()=>setShowprintProf(true)} ><FontAwesomeIcon size='2x' icon={faFilePdf} />Etat-Prof</Button>
      <Button variant="contained" color="primary" className='col-3 mb-2' style={{"marginLeft":20}} onClick={()=>setShowprintControle(true)} ><FontAwesomeIcon size='2x' icon={faFilePdf} />Etat-Controle</Button>
      </Col>
     </Row>


         {/************************Affichage table ************/}
     <Grid container spacing={2}>
      <Grid item xs={12}>
      <Table aria-label="striped table" striped>
      <thead>
        <tr>
          <th>select</th>
          <th style={{ width: '10%' }}>Date d'absence</th>
          <th style={{ width: '10%' }}>Date</th>
          <th>Nom</th>
          <th>Prénom</th>
          <th>Filière</th>
          <th>Module</th>
          <th>SM</th>
          <th>GR</th>
          <th>Jour</th>
          <th style={{ width: '15%' }}>Créneau</th>
          <th style={{ width: '10%' }}>Salle</th>
          <th><FontAwesomeIcon size={fa9} icon={faEdit} /></th>
        </tr>
      </thead>
      <tbody>
        {allRattrapages.value.map(
          (rat,index)=>(<tr key={index}>
          <td> <Checkbox {...label}  color="success"  checked={selectedRows.includes(rat.id)} onClick={(event)=>{addOrDeleteRattrapage(event,rat); }} /> </td>
          <td>{rat.date_absence?.split('T')[0]}</td>
          <td>{rat.date_rattrapage?.split('T')[0]}</td>
          <td>{rat.nom_fr}</td>
          <td>{rat.prenom_fr}</td>
          <td>{rat.libelle_filiere}</td>
          <td>{rat.module_name}</td>
          <td>S{rat.id_semestre}</td>
          <td>{rat.groupe}</td>
          <td>{rat.jour}</td>
          <td>{rat.creneau}</td>
          <td>{rat.salle}</td>
          <td><Button onClick={()=>{handleShowDel(rat.id)}} variant='text' ><FontAwesomeIcon icon={faTrash} color='red' /></Button></td>
        </tr>)
        )}
       
        
      </tbody>
    </Table>
      </Grid>
     </Grid>
     {/*****************Fin table***************** */}

    
    

{/***********Modal message addRattrapage*********************/}
    <Modal show={show} onHide={handleClose}>
    
        <Modal.Header closeButton>
          <Modal.Title>Rattrapage </Modal.Title>
        </Modal.Header>
        <Modal.Body className={classe}>{ <div dangerouslySetInnerHTML={{ __html: msg }} /> }</Modal.Body>
        <Modal.Footer>
          <Stack spacing={2} direction='row'>
         
          <Button variant='contained' color='info' onClick={()=>{handleClose();}}>
            OK
          </Button>
          </Stack>
        </Modal.Footer>
    </Modal>
{/***********Modal delete*********************/}
    <Modal show={showDel} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer ce rattrapage?</Modal.Body>
        <Modal.Footer>
          <Stack spacing={2} direction='row'>
          <Button variant='contained' color='warning' onClick={handleCloseDelete}>
            Annuler
          </Button>
          <Button variant='contained' color='info' onClick={()=>{deleteRattrapage(del);handleCloseDelete(); console.log("id à supprimé:",del)}}>
            Oui
          </Button>
          </Stack>
        </Modal.Footer>
      </Modal>
      
{/***********Modal print rattrapage étudiant*********************/}
<Modal className='modal-xl' show={showprint} onHide={handleCloseprint}>
        <Modal.Header closeButton>
          <Modal.Title>Rattrapage </Modal.Title>
        </Modal.Header>
        <Modal.Body>
           {/************************Affichage table dans modal************/}
     <Grid container spacing={2}>
      <Grid item xs={12}>
      <Table aria-label="striped table" striped>
      <thead>
        <tr>
         
          <th style={{ width: '10%' }}>Date d'absence</th>
          <th style={{ width: '10%' }}>Date</th>
          <th>Nom</th>
          <th>Prénom</th>
          <th>Filière</th>
          <th>Module</th>
          <th>SM</th>
          <th>GR</th>
          <th>Jour</th>
          <th style={{ width: '15%' }}>Créneau</th>
          <th style={{ width: '10%' }}>Salle</th>
         
        </tr>
      </thead>
      <tbody>
        {ratrappageListPrint.value.map(
          (rat,index)=>(<tr key={index}>
          
          <td>{rat.date_absence?.split('T')[0]}</td>
          <td>{rat.date_rattrapage?.split('T')[0]}</td>
          <td>{rat.nom_fr}</td>
          <td>{rat.prenom_fr}</td>
          <td>{rat.libelle_filiere}</td>
          <td>{rat.module_name}</td>
          <td>S{rat.id_semestre}</td>
          <td>{rat.groupe}</td>
          <td>{rat.jour}</td>
          <td>{rat.creneau}</td>
          <td>{rat.salle}</td>
          
        </tr>)
        )}
       
        
      </tbody>
    </Table>
      </Grid>
     </Grid>
     {/*****************Fin table***************** */}
    
        </Modal.Body>
        <Modal.Footer>
          <Stack spacing={2} direction='row'>
         
          <Button variant='contained' color='info' onClick={()=>{handleCloseprint();}}>
            OK
          </Button>
          <Button variant='contained' color='info' onClick={()=>{exportPDFetudiant(); handleCloseprint();}}>
            Print
          </Button>
          </Stack>
        </Modal.Footer>
    </Modal>
{/***********Fin Modal print rattrapage étudiant*********************/}

{/***********Modal print rattrapage Prof*********************/}
<Modal className='modal-xl' show={showprintProf} onHide={handleCloseprintProf}>
        <Modal.Header closeButton>
          <Modal.Title>Rattrapage - Professeur </Modal.Title>
        </Modal.Header>
        <Modal.Body>
           {/************************Affichage table dans modal************/}
     <Grid container spacing={2}>
      <Grid item xs={12}>
      <Table aria-label="striped table" striped>
      <thead>
        <tr>
         
          <th style={{ width: '10%' }}>Date d'absence</th>
          <th style={{ width: '10%' }}>Date</th>
          <th>Nom</th>
          <th>Prénom</th>
          <th>Filière</th>
          <th>Module</th>
          <th>SM</th>
          <th>GR</th>
          <th>Jour</th>
          <th style={{ width: '15%' }}>Créneau</th>
          <th style={{ width: '10%' }}>Salle</th>
         
        </tr>
      </thead>
      <tbody>
        {ratrappageListPrint.value.map(
          (rat,index)=>(<tr key={index}>
          
          <td>{rat.date_absence?.split('T')[0]}</td>
          <td>{rat.date_rattrapage?.split('T')[0]}</td>
          <td>{rat.nom_fr}</td>
          <td>{rat.prenom_fr}</td>
          <td>{rat.libelle_filiere}</td>
          <td>{rat.module_name}</td>
          <td>S{rat.id_semestre}</td>
          <td>{rat.groupe}</td>
          <td>{rat.jour}</td>
          <td>{rat.creneau}</td>
          <td>{rat.salle}</td>
          
        </tr>)
        )}
       
        
      </tbody>
    </Table>
      </Grid>
     </Grid>
     {/*****************Fin table***************** */}
    
        </Modal.Body>
        <Modal.Footer>
          <Stack spacing={2} direction='row'>
         
          <Button variant='contained' color='info' onClick={()=>{handleCloseprintProf();}}>
            OK
          </Button>
          <Button variant='contained' color='info' onClick={()=>{exportPDFprof(); handleCloseprintProf();}}>
            Print
          </Button>
          </Stack>
        </Modal.Footer>
    </Modal>
{/***********Fin Modal print rattrapage Prof*********************/}

{/***********Modal print rattrapage Controle*********************/}
<Modal className='modal-xl' show={showprintControle} onHide={handleCloseprintControle}>
        <Modal.Header closeButton>
          <Modal.Title>Rattrapage - Controle </Modal.Title>
        </Modal.Header>
        <Modal.Body>
           {/************************Affichage table dans modal************/}
     <Grid container spacing={2}>
      <Grid item xs={12}>
      <Table aria-label="striped table" striped>
      <thead>
        <tr>
         
          <th style={{ width: '10%' }}>Date d'absence</th>
          <th style={{ width: '10%' }}>Date</th>
          <th>Nom</th>
          <th>Prénom</th>
          <th>Filière</th>
          <th>Module</th>
          <th>SM</th>
          <th>GR</th>
          <th>Jour</th>
          <th style={{ width: '15%' }}>Créneau</th>
          <th style={{ width: '10%' }}>Salle</th>
         
        </tr>
      </thead>
      <tbody>
        {ratrappageListPrint.value.map(
          (rat,index)=>(<tr key={index}>
          
          <td>{rat.date_absence?.split('T')[0]}</td>
          <td>{rat.date_rattrapage?.split('T')[0]}</td>
          <td>{rat.nom_fr}</td>
          <td>{rat.prenom_fr}</td>
          <td>{rat.libelle_filiere}</td>
          <td>{rat.module_name}</td>
          <td>S{rat.id_semestre}</td>
          <td>{rat.groupe}</td>
          <td>{rat.jour}</td>
          <td>{rat.creneau}</td>
          <td>{rat.salle}</td>
          
        </tr>)
        )}
       
        
      </tbody>
    </Table>
      </Grid>
     </Grid>
     {/*****************Fin table***************** */}
    
        </Modal.Body>
        <Modal.Footer>
          <Stack spacing={2} direction='row'>
         
          <Button variant='contained' color='info' onClick={()=>{handleCloseprintControle();}}>
            OK
          </Button>
          <Button variant='contained' color='info' onClick={()=>{exportPDFcontrole(); handleCloseprintControle();}}>
            Print
          </Button>
          </Stack>
        </Modal.Footer>
    </Modal>
{/***********Fin Modal print rattrapage controle*********************/}
      {/*----------------------------------------------------*/}
      <Grid item xs={12}>
      <hr style={{ height: '15px', backgroundColor: 'red', border: 'none', marginBottom:'200px' }} />
      </Grid>
       {/*--------------------------------Page à imprimer étudiants--------------------------------*/}
     <Grid container spacing={2}  ref={contentRef}>
     <Grid item xs={12} className='text-center'>
      <img src={logo} className='img-fluid' />
      </Grid>
      <Grid item xs={12} className='text-center' style={{fontFamily:'Aljazeera'}}>
          <h2 >حصص التعويض  </h2>
          <h2>رسم السنة الجامعية {yearSignal.value+1}/{yearSignal.value}</h2>
          <h3>من : {dateMin.value?.split('T')[0]} الى : {dateMax.value?.split('T')[0]}</h3>
      </Grid>
      <Grid item xs={12}>
      <Table aria-label="striped table" striped dir="rtl">
      <thead>
        <tr>
        <th>المسلك</th>
        <th>الأستاذ (ة)</th>
        <th>الوحدة</th>
        <th>القاعة</th>
        <th>اليوم</th>
        <th>تاريخ التعويض</th>
        <th>التوقيت</th>
          <th style={{ width: '10%' }}>تاريخ الغياب</th>
          
          
          <th>SM</th>
          <th>GR</th>
          
         
          
         
        </tr>
      </thead>
      <tbody>
        {ratrappageListPrint.value.map(
          (rat,index)=>(<tr key={index}>
          <td>{rat.libelle_filiere}</td>
          <td>{rat.nom_fr} {rat.prenom_fr}</td>
          <td>{rat.module_name}</td>
          <td>{rat.salle}</td>
          <td>{rat.jour}</td>
          <td>{rat.date_rattrapage?.split('T')[0]}</td>
          <td>{rat.creneau}</td>
          <td>{rat.date_absence?.split('T')[0]}</td>
          <td>S{rat.id_semestre}</td>
          <td>{rat.groupe}</td>
          
          
          
          
        </tr>)
        )}
      </tbody></Table></Grid></Grid>
     {/*--------------------------------Fin page à imprimer étudiants-----------------------------*/}
     {/*--------------------------------Page à imprimer professeur--------------------------------*/}
     <Grid container spacing={2}  ref={contentRef2}>
     <Grid item xs={12} className='text-center'>
      <img src={logo} className='img-fluid' />
      </Grid>
      <Grid item xs={12} className='text-center' style={{fontFamily:'Aljazeera'}} dir='rtl'>
          <h2 >جدول التعويض للاستاذ (ة): {prenomProfRatt.value} {nomProfRatt.value}</h2>
          <h2>رسم السنة الجامعية {yearSignal.value+1}/{yearSignal.value}</h2>
          <h3>من : {dateMin.value?.split('T')[0]} الى : {dateMax.value?.split('T')[0]}</h3>
      </Grid>
      <Grid item xs={12}>
      <Table aria-label="striped table " striped dir="rtl">
      <thead>
        <tr>
        <th>المسلك</th>
        <th>الوحدة</th>
        <th>GR</th>
        <th>SM</th>
        <th style={{ width: '10%' }}>تاريخ الغياب</th>
        <th>تاريخ التعويض</th>
        <th>اليوم</th>
        <th>التوقيت</th>
        <th>القاعة</th>
        </tr>
      </thead>
      <tbody>
        {ratrappageListPrint.value.map(
          (rat,index)=>(<tr key={index}>
          <td>{rat.libelle_filiere}</td>
          <td>{rat.module_name}</td>
          <td>{rat.groupe}</td>
          <td>S{rat.id_semestre}</td>
          <td>{rat.date_absence?.split('T')[0]}</td>
          <td>{rat.date_rattrapage?.split('T')[0]}</td>
          <td>{rat.jour}</td>
          <td>{rat.creneau}</td>
          <td>{rat.salle}</td>
          
        </tr>)
        )}
      </tbody></Table></Grid></Grid>
     {/*--------------------------------Fin page à imprimer prof-----------------------------*/}
     {/*--------------------------------Page à imprimer controle--------------------------------*/}
     <Grid container spacing={2}  ref={contentRef3}>
     <Grid item xs={12} className='text-center'>
      <img src={logo} className='img-fluid' />
      </Grid>
      <Grid item xs={12} className='text-center' style={{fontFamily:'Aljazeera'}}>
          <h2 >حصص التعويض  </h2>
          <h2>رسم السنة الجامعية {yearSignal.value+1}/{yearSignal.value}</h2>
          <h3>من : {dateMin.value?.split('T')[0]} الى : {dateMax.value?.split('T')[0]}</h3>
      </Grid>
      <Grid item xs={12}>
      <Table aria-label="table table-bordered" striped dir="rtl" style={{
                border: '1px solid black', 
                borderCollapse: 'collapse'
            }}>
      <thead>
        <tr>
        <th style={{ border: '1px solid black' }}>المسلك</th>
        <th style={{ border: '1px solid black' }}>الأستاذ (ة)</th>
        <th style={{ border: '1px solid black' }}>تاريخ الغياب</th>
        <th style={{ border: '1px solid black' }}>القاعة</th>
        <th style={{ border: '1px solid black' }}>اليوم</th>
        <th>تاريخ التعويض</th>
        <th style={{ border: '1px solid black' }}>التوقيت</th>
        <th style={{ border: '1px solid black' }}>غ.أ</th>
        <th style={{ border: '1px solid black' }}>غ.ط</th>
          
        </tr>
      </thead>
      <tbody>
        {ratrappageListPrint.value.map(
          (rat,index)=>(<tr key={index}>
          <td style={{ border: '1px solid black' }}>{rat.libelle_filiere}</td>
          <td style={{ border: '1px solid black' }}>{rat.nom_fr} {rat.prenom_fr}</td>
          <td style={{ border: '1px solid black' }}>{rat.date_absence?.split('T')[0]}</td>
          <td style={{ border: '1px solid black' }}>{rat.salle}</td>
          <td style={{ border: '1px solid black' }}>{rat.jour}</td>
          <td style={{ border: '1px solid black' }}>{rat.date_rattrapage?.split('T')[0]}</td>
          <td style={{ border: '1px solid black' }}>{rat.creneau}</td>
          <td style={{ border: '1px solid black' }}></td>
          <td style={{ border: '1px solid black' }}></td>
        </tr>)
        )}
      </tbody></Table></Grid></Grid>
     {/*--------------------------------Fin page à imprimer controle-----------------------------*/}
        </Box>
      )
}

export default AddRattrapage