import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import {Container,Form, Row, Col, Table,Modal} from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import api from '../../config/axios';
import { AuthenticateContext } from '../../config/AuthContext';
import Menu from './Menu';
import {jours, creneaux} from './../../config/JourCreneauSalles';
import { Button, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa3, fa9, faAdd, faDeleteLeft, faFileExcel, faFilePdf, faForward, faForwardFast, faForwardStep, faMagic, faTrash, faUserAlt, faUserAltSlash } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import Footer from '../Footer';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import logo from '../../images/logo.png';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

function AddAbsences() {
    const [authInfo,setAuthInfo] = useContext(AuthenticateContext);
    const { register,reset, handleSubmit} = useForm()
    const nom = localStorage.getItem('nom');
    const role = localStorage.getItem('role');
    const [year,setYear] = useState(0);
    const [plannings,setPlannings] = useState([]);
    const [filtredPlannings,setFiltredPlannings] = useState([]);
    const [salles,setSalles] = useState([]);
    const [indicateur, setIndicateur] = useState(false);
    const [rechSession,setRechSession] = useState("");
    const [rechSem,setRechSem] = useState("");
    const [rechJour,setRechJour] = useState("");
    const [rechHeure,setRechHeure] = useState("");
    const [dateAbsence,setDateAbsence] = useState("");
    const [day,setDay] = useState("");

    const [btnIco,setBtnIco] = useState([]);
    let indicateurAbsence = [];
    const [absentProfs, setAbsentProfs]  = useState([]);
    const [inputValuesRemarque, setInputValuesRemarque] = useState(['']);
    //-----------------Modal-------------------
    const [show, setShow] = useState(false);
    const [del, setDel] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = () => {setShow(true); }
//-----------------------------------------------------
const joursCorrespondance = {
  Lundi: 'الإثنين',
  Mardi: 'الثلاثاء',
  Mercredi: 'الأربعاء',
  Jeudi: 'الخميس',
  Vendredi: 'الجمعة',
  Samedi: 'السبت',
  Dimanche: 'الأحد',
};
//---------------------------------------------
const getDayFromDate = (dateString) => {
  const date = new Date(dateString);
  const options = { weekday: 'long' }; // Pour obtenir le nom complet du jour
  return date.toLocaleDateString('fr-FR', options); // 'fr-FR' pour obtenir le nom en français
};
//------------------get day & date saisie
const getDayAndDateSaisie = useCallback(()=>{
  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay();
  console.log(jours[dayOfWeek]);
  setDay(jours[dayOfWeek]);
  setRechJour(jours[dayOfWeek].toLowerCase());
  const formattedDate = currentDate.toISOString().substring(0, 10);
  console.log(formattedDate);
  setDateAbsence(formattedDate);
  setDay(getDayFromDate(formattedDate).charAt(0).toUpperCase() + getDayFromDate(formattedDate).slice(1))
},[]);
    //---------------------all salle ----------------------
const getAllSalles =useCallback(async () =>{
    console.log("im getAllSalles im executing.....");
    const url = "/api/salles";
    api.get(url)
    .then(rep =>{
       // console.log(rep.data.salles);
        setSalles(rep.data.salles);
    }).catch(err => {
      console.error(err);
    });
  },[]);
//===========================getAllPlanningsByYear=====================================
const getAllPlanningsByYear = useCallback(async (annee) => {
    console.log("im getAllPlanningsByYear im executing.....");
    let A1 = annee;
    let A2 = A1 + 1;
    const url = "api/allplannins/"+A1+"/"+A2;
      api.get(url)
      .then(rep =>{
          let lst = rep.data.plannings;
          setPlannings(lst);
          
          
          for (let i = 0; i < lst.length; i++){
            indicateurAbsence[i] = false;
          }
          setBtnIco(indicateurAbsence);
          console.log("indicateurAbsence: ",indicateurAbsence);
          //------------------------------------------------
          setIndicateur(true);
         // console.log(rep.data.plannings);
      })
      .catch(err => {
         console.error(err);
      });    
  },[]);
   //-----------------------------------------
      function absentPresent(id) {
        setBtnIco(prevItems => {
          return prevItems.map(
            (item,i)=> {
            if (i == id) {
              console.log("from toggle item id=",id)
              console.log(item);
              return !item;
            }
            return item;
          });
        });
      }
      //===================add absent prof to list absentProfs============
      const addAbsentProf = (p,i)=>{
        if(!btnIco[i]){
          setAbsentProfs(prevItems=>[...prevItems,p]);
        }else{
          let tempo = absentProfs.filter(item=>item!=p);
          setAbsentProfs(tempo);
        }
        console.log(btnIco[i])
        console.log(absentProfs);
      }
      //===================add remarques forabsent prof ============
      const handleInputChangeAddRemarks = (event,index) => {
        const newinputValuesRemarque = [...inputValuesRemarque];
        newinputValuesRemarque[index] = event.target.value;
        setInputValuesRemarque(newinputValuesRemarque);
      };
      //--------------------------
      const sendAbsenceToDataBase = () => {
       absentProfs.map((p,i)=>{
        console.log("Date Absence: ",dateAbsence);
            api.post('/api/absence',
                      {                        
                          "id_professor":p.id_professor,
                          "nom":p.nom_fr,
                          "prenom":p.prenom_fr,
                          "module_name":p.module_name,
                          "libelle_filiere":p.libelle_filiere,
                          "date_absence":dateAbsence,
                          "jour":p.jour,
                          "creneau":p.creneau,
                          "salle":p.salle,
                          "annee_univ":p.annee_univ,
                          "remarque":inputValuesRemarque[i]
                      }
                    ).then(
                      rep => console.log(rep)
                    ).catch(err => {
                      console.log(err);
                    });
       }); // fin absentProfs.map
       
        setInputValuesRemarque(['']);
      };
      
      //--------------------------------------------
  //---------------------get Anne univ ----------------------
  const getYearUniv =useCallback(async () =>{
    console.log("im getYearUniv im executing.....");
    const url = "/api/anneeuniv";
    api.get(url)
    .then(rep =>{
       // console.log(rep.data.annee[0].annee_univ);
        setYear(rep.data.annee[0].annee_univ);
        getAllPlanningsByYear(rep.data.annee[0].annee_univ);
        
    }).catch(err => {
      console.error(err);
    });
  },[getAllPlanningsByYear]);

  //-----------------------filter---------------------------
const filterPlanning = useCallback(()=>{
    
      console.log("im filter im executing.....");
      let lst = plannings?.filter(p => {
        return rechSession === '' ? p : p.id_sessions==rechSession;
      }).filter(p => {
        return rechSem === '' ? p : p.id_semestre==rechSem;
      }).filter(p => {
        return rechJour.toLowerCase() === '' ? p : p.jour.toLowerCase().includes(rechJour);
      }).filter(p => {
        return rechHeure === '' ? p : p.creneau.toLowerCase().includes(rechHeure);
      });
      //--------------tri-------------------
  const sortedRattrapageList = [...lst].sort((a, b) =>
    a.nom_fr.localeCompare(b.nom_fr)
  );
      setFiltredPlannings(sortedRattrapageList);
      setIndicateur(false);
   // } //if
    
    },[rechJour,rechHeure,rechSem,rechSession,indicateur]);
//-----------------------------------------------------------
//==============================export Excel========================
const dataForExport = useCallback(()=>{
  let lstData = plannings?.filter(p => {
    return rechSession === '' ? p : p.id_sessions==rechSession;
  }).filter(p => {
    return rechSem === '' ? p : p.id_semestre==rechSem;
  }).filter(p => {
    return rechJour.toLowerCase() === '' ? p : p.jour.toLowerCase().includes(rechJour);
  }).filter(p => {
    return rechHeure === '' ? p : p.creneau.toLowerCase().includes(rechHeure);
  });
   //--------------tri-------------------
   const sortedLstData = [...lstData].sort((a, b) =>
    a.nom_fr.localeCompare(b.nom_fr)
  );
  var wb = XLSX.utils.book_new(),
   ws = XLSX.utils.json_to_sheet(sortedLstData);
  XLSX.utils.book_append_sheet(wb, ws,"profs");
  let filename = "Absence_"+dateAbsence+".xlsx";
  XLSX.writeFile(wb, filename);
  
 },[rechJour,rechHeure,rechSem,rechSession,indicateur])

  //--------------------------------------------------------
  useEffect(()=>{
    getDayAndDateSaisie();
    
   },[getDayAndDateSaisie]);
   //--------------------------------------------------------
    useEffect(()=>{
        getYearUniv();
        getAllSalles();
       },[getAllSalles,getYearUniv]);
    //--------------------------------------------
      useEffect(()=>{
        filterPlanning();
       },[filterPlanning]);
  //------------------------print pdf----------------------------------------
  const contentRef = useRef(null);
  const exportPDFAbsence = () => {
    const input = contentRef.current;
    html2canvas(input, { 
        scale: 2,
        useCORS: true 
    }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210; 
        const pageHeight =285;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        while (heightLeft > pageHeight) {
            heightLeft -= pageHeight;
            position -= pageHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        }
        let fileName = dateAbsence+"_absence.Pdf";//
        pdf.save(fileName);
        //ratrappageListPrint.value =[] // vider la liste
    });
};



  //--------------------------------------------------------------
  return (
    <Container>
    <Menu/>
    <Row>
   <Col>
    <hr className='trait'/>
    <h3>Ajout d'absences</h3>
    </Col>
   </Row>
   
   <Row>
   <Col>
    <hr className='trait'/>
    <table><tr>
        <td>
        <div className='form-group'>
                <label className='update_label'>Date d'absence</label>
               <input type='date' value={dateAbsence} onChange={(e)=>{setDateAbsence(e.target.value);}} className='form-control modif'/>
          </div>
        </td>
        <td>
            <div className='form-group'>
                <label className='update_label'>Session</label>
        <select  value={rechSession}  className='form-control myselect' onChange={(e) => {setRechSession(e.target.value.toLowerCase()); filterPlanning()}}>
            <option selected value="">All </option>
            <option value="1" selected>Automne </option>
            <option value="2" selected>Printemps </option>
          </select>
          </div>
        </td>
        
        <td>
        <div className='form-group'>
                <label className='update_label'>Jour</label>
        <select className='form-control myselect' onChange={(e) => {setRechJour(e.target.value.toLowerCase()); filterPlanning()}}>
            <option value={day} selected>{day} </option>
            {jours.map((j,i) =><option value={j} key={i}>{j}</option>)}
          </select>
          </div>
        </td>
        
        <td>
        <div className='form-group'>
                <label className='update_label'>Heure</label>
        <select className='form-control myselect' onChange={(e) => {setRechHeure(e.target.value.toLowerCase()); filterPlanning()}}>
            <option value="" selected>All </option>
            {creneaux.map((c,i) =><option value={c} key={i}>{c}</option>)}
          </select>
          </div>
        </td>
        </tr></table>
    </Col>
   </Row>
   <Row>
   <Col className='mt-2'>
        <Button variant="contained" color="success" className='col-3 mb-2' style={{marginRight:10}} onClick={()=>dataForExport()} ><FontAwesomeIcon size='2x' icon={faFileExcel} /></Button>
        <Button variant="contained" color="primary" className='col-3 mb-2' onClick={()=>exportPDFAbsence()} ><FontAwesomeIcon size='2x' icon={faFilePdf} /></Button>
        
  </Col>
</Row>
   <Row>
    <Col>
    <Table striped bordered hover className='tab'>
        <thead >
          <tr>
            <th>N°</th>
            <th>Professeur</th>
            {/*<th>Module</th>
            <th>Filière</th>
            <th>Département</th>
            <th>Session</th>
            <th>Semestre</th>*/}
            <th>Salle</th>
            <th>Jour</th>
            <th>Créneau</th>
            
            
           {/* <th>Hébdo</th>
            <th>Note</th>*/}
            <th>Absence</th>
          </tr>
        </thead>
            <tbody>
            
              {
              filtredPlannings.map((p,i) =><tr key={i}>
            <td>{i+1}</td>
            <td>{p.nom_fr} {p.prenom_fr}</td>
           {/* <td>{p.module_name}</td>
            <td>{p.libelle_filiere}</td>
            <td>{p.libelle_departement}</td>
            <td>{p.id_sessions==1?"Automne":"Printemps"}</td>
              <td>{"SM"+p.id_semestre}</td>*/}
            <td>{p.salle}</td>
            <td>{p.jour}</td>
            <td>{p.creneau}</td>
            
           {/* <td>{p.hebdo==0?"Non":"Oui"}</td>
            <td>{p.note}</td>*/}
	    <td><Button onClick={()=>{absentPresent(i); addAbsentProf(p,i);console.log(absentProfs)}} variant='text' ><FontAwesomeIcon className='fa-3x' hidden={!btnIco[i]} icon={faUserAltSlash} color='red' /><FontAwesomeIcon className='fa-3x' hidden={btnIco[i]} icon={faUserAlt} color='info' /></Button></td>
              </tr>)}
            </tbody>
      </Table>
    </Col>
   </Row>
   <Row>
      <Col><button className='btn btn-primary col-6 offset-3' onClick={()=>{handleShow();console.log(absentProfs);}}> <FontAwesomeIcon className='fa-3x' icon={faForward} color='info' /></button></Col>
   </Row>
   <Footer/>
   

   {/***********Modal*********************/}
<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Absence</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h2 className='text-center text-info'>Liste d'absence: {absentProfs.length}</h2>
            <h3 className='text-center text-danger'>{dateAbsence}</h3>
            <Table striped bordered hover className='tab'>
        <thead >
          <tr>
            <th>Professeur</th>
            <th>Salle</th>
            <th>Jour</th>
            <th>Heure</th>
            <th>Remarques</th>
          </tr>
          </thead>
          <tbody>
            {absentProfs.map((p,i) =><tr key={i}>
              <td>{p.nom_fr} {p.prenom_fr}</td>
              <td>{p.salle}</td>
              <td>{p.jour}</td>
              <td>{p.creneau}</td>
              
              <td>
                <input type="text" name={"remark"+i} onChange={(e)=>handleInputChangeAddRemarks(e,i)} onc className='form-control'/>
              </td>
              </tr>)}
          </tbody>
          </Table>

        </Modal.Body>
        <Modal.Footer>
          <Stack spacing={2} direction='row'>
          <Button variant='contained' color='warning' onClick={handleClose}>
            Annuler
          </Button>
          <Button variant='contained' color='info' onClick={()=>{sendAbsenceToDataBase();handleClose();}}>
            Valider la liste d'absence
          </Button>
          </Stack>
        </Modal.Footer>
      </Modal>
      <Row>
      <Col>
      <hr style={{ height: '15px', backgroundColor: 'red', border: 'none', marginBottom:'200px' }} />
      </Col>
      </Row>

    {/***************** Print pdf********************/}
    <Grid container spacing={2}  ref={contentRef}>
     <Grid item xs={12} className='text-center'>
      <img src={logo} className='img-fluid' />
      </Grid>
      <Grid item xs={12} className='text-center' style={{fontFamily:'Aljazeera'}}>
          <h2 > مراقبة حصص التدريس  </h2>
          <h2>{joursCorrespondance[getDayFromDate(dateAbsence).charAt(0).toUpperCase() + getDayFromDate(dateAbsence).slice(1)]}: {dateAbsence}</h2>
          <h2>رسم السنة الجامعية {year+1}/{year}</h2>
          
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
      <Table  className='table table-bordered' style={{
                border: '1px solid black', 
                borderCollapse: 'collapse'
            }}>
        <thead >
          <tr>
            <th style={{ border: '1px solid black' }}>N°</th>
            <th>Professeur</th>
            {/*<th>Module</th>
            <th>Filière</th>
            <th>Département</th>
            <th>Session</th>
            <th>Semestre</th>*/}
            <th style={{ border: '1px solid black' }}>Salle</th>
            <th style={{ border: '1px solid black' }}>Jour</th>
            <th style={{ border: '1px solid black' }}>Créneau</th>
            
            
           {/* <th>Hébdo</th>
            <th>Note</th>*/}
            <th style={{ border: '1px solid black' }}>Abs. Prof</th>
            <th style={{ border: '1px solid black' }}>Abs. Etud</th>
          </tr>
        </thead>
            <tbody>
            
              {
              filtredPlannings.map((p,i) =><tr key={i}>
            <td style={{ border: '1px solid black' }}>{i+1}</td>
            <td style={{ border: '1px solid black' }}>{p.nom_fr} {p.prenom_fr}</td>
           {/* <td>{p.module_name}</td>
            <td>{p.libelle_filiere}</td>
            <td>{p.libelle_departement}</td>
            <td>{p.id_sessions==1?"Automne":"Printemps"}</td>
              <td>{"SM"+p.id_semestre}</td>*/}
            <td style={{ border: '1px solid black' }}>{p.salle}</td>
            <td style={{ border: '1px solid black' }}>{p.jour}</td>
            <td style={{ border: '1px solid black' }}>{p.creneau}</td>
            
           {/* <td>{p.hebdo==0?"Non":"Oui"}</td>
            <td>{p.note}</td>*/}
	    <td style={{ border: '1px solid black' }}></td>
      <td style={{ border: '1px solid black' }}></td>
              </tr>)}
            </tbody>
      </Table>
      </Grid>
      <Grid item xs={1}></Grid>
      </Grid>
    
      
  {/**********************fin print pdf************************ */}
   </Container>
  )
}

export default AddAbsences